import React, { useEffect } from "react";
import Logo from "../Images/Logo.svg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Form from "react-bootstrap/Form";

export default function Navbar({ changeLang }) {
  const { t } = useTranslation();

  const changeLanguage = (e) => {
    changeLang(e.target.value);
    localStorage.setItem("LANGUAGE", e.target.value);
  };

  useEffect(() => {
    changeLang(localStorage.getItem("LANGUAGE"));
  }, []);

  return (
    <div className="Navbar">
      <div className="container">
        <div className="signup-head">
          <Link to="/">
            <img className="logo" src={Logo} alt="logo-brand" />
          </Link>

          <Form.Select
            aria-label="Default select example"
            data-aos="fade"
            data-aos-duration="2000"
            className="language-button"
            name="languages"
            value={localStorage.getItem("LANGUAGE") || "en"}
            id="languages"
            onChange={changeLanguage}
          >
            <option value="en">EN</option>
            <option value="ru">RU</option>
            <option value="nl">NL</option>
            <option value="lt">LT</option>
            <option value="uk">UA</option>
            <option value="es">ES</option>
            <option value="pl">PL</option>
          </Form.Select>
        </div>
      </div>
    </div>
  );
}
