const TranslationRU = {
  homeTitle: 'Profyer — это сервис, который помогает клиентам и специалистам встретиться',
  homeInfo: 'Клиенты будут находить здесь профессионалов для решения любых задач по всей территории Евросоюза — строителей, сантехников, парикмахеров, репетиторов и многих других.',
  homeInfo2: 'Оставьте свои контакты, и Вы:',
  formMasterInfo1: 'Оставьте свои контакты, и Вы:',
  formMasterInfo2: 'узнаете первыми о запуске Profyer',
  formMasterInfo3: 'будете получать бесплатные заявки из наших групп в социальных сетях!',
  formUserInfo1: "Частные специалисты и компании смогут находить с помощью Profyer клиентов по всей территории Евросоюза.",
  registerUserTittle2: "Клиенты будут находить здесь профессионалов для решения любых задач по всей территории Евросоюза — строителей, сантехников, электриков, садовников, уборщиков, нянь и многих других.",
  registerUserTittle3 : "Оставьте свои контакты, и Вы узнаете первыми о запуске Profyer!",
  
  survayClientTittle: 'Ура - ваша заявка принята!',
  survayClientInfo1: ', в настоящий момент мы трудимся над тем, чтобы сервис Profyer стал по настоящему полезным и удобным сервисом по поиску специалистов различных профессий.',
  survayClientInfo2: 'Уделите нам 3 минуты и пройдите короткий опрос - это важно!',
  survayClientInfo3: 'Спасибо, Команда Profyer.',
  name: 'Имя',
  languages: 'Языки',
  surname: 'Фамилия',
  choice: 'Выберите',
  country: 'Cтрана',
  service: 'Профессия',
  client: 'Я - Клиент',
  specialist: 'Я - Специалист',
  register: 'Зарегистрироваться',
  startToSurway: "Пройти опрос",
  next : "Далее",
  previous : "Назад",
  privacyIngo : 'Регистрируясь, Вы соглашаетесь с нашим ',
  privacyText : 'Положением о конфиденциальности',
  next: "Далее",
  previous: "Назад",
  social: "Наши Facebook группы:",



  send: 'Отправить',
  russian: 'Русский',
  errorLang: "Язык обязателен!",
  errorService: "Сервис обязателен!",
  policy1: "Вы соглашаетесь с ",
  policy2: "Правилами и условиями недобросовестных клиентов, Политикой конфиденциальности.",
  thanks1: "Спасибо, что делаете наш сервис полезнее!",
  thanks2: "Спасибо, что делаете наш сервис полезнее!",
  surwayClientSpan : "В поиске заказов прямо сейчас? Все объявления в наших группах в Facebook!",
  surwaySpecialistSpan : "Требуется специалист прямо сейчас? Разместите объявление в наших группах в Facebook!",
  selectLabel: " элементы выбраны",
}

export default TranslationRU