import { useLocation, Routes, Route, Navigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

import i18n from "i18next";
import i18next from "i18next";
import { initReactI18next } from 'react-i18next';

import TranslationRU from './Locale/Ru'
import TranslationEN from './Locale/En'
import TranslationNL from './Locale/Nl'
import TranslationLT from './Locale/Lt'
import TranslationUA from './Locale/Ua'

import "primereact/resources/themes/lara-light-indigo/theme.css";
import TranslationES from './Locale/Es'
import TranslationPL from './Locale/Pl'

import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

// Style
import './App.scss';
import './Responsive.scss'

// Components
import Opros from './Components/Opros';
import Navbar from './Components/Navbar';
import Signup from './Components/Signup';
import Loader from './Components/Loader';
import Footer from './Components/Footer';
import ScrollToTop from './Components/ScrollToTop';
import SurvayClient from './Components/SurvayClient';
import PrivacyPolicy from './Components/PrivacyPolicy';

// Pages
import SignupUser from './Pages/SignupUser';
import SignupSpaecialist from './Pages/SignupSpaecialist';
import ClientFirstSurway from './Components/Clientsurways/ClientFirstSurway';
import SpecialistSuccessful from "./Components/Specialistsurways/SpecialistSuccessful"

i18n.use(initReactI18next).init({
  resources: {
    ru: { translation: TranslationRU },
    en: { translation: TranslationEN },
    nl: { translation: TranslationNL },
    lt: { translation: TranslationLT },
    uk: { translation: TranslationUA },
    es: { translation: TranslationES },
    pl: { translation: TranslationPL }
  },
  lng: localStorage.getItem("LANGUAGE") || "en",
  fallbackLng: localStorage.getItem("LANGUAGE") || "en",
});

function App() {
  const { pathname } = useLocation();

  const changeLang = (value) => {
    i18next.changeLanguage(value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const [loading, setIsloading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsloading(false)
    }, 1730)
  }, [])

  return (
    <div className="App">
      {
        loading ?
          <>
            <Loader />
          </>
          :
          <>
            <ScrollToTop />
            <Navbar changeLang={changeLang} />
            <Routes>
              <Route path='/' element={<Signup />} />
              <Route path='/client-registration' element={<SignupUser />} />
              <Route path='/specialist-registration' element={<SignupSpaecialist />} />

              {localStorage.getItem("UserID") && localStorage.getItem("UserTYPE") == 1 ?
                <>
                  <Route path='/survay-client' element={<SurvayClient />} />
                  <Route path='/client-survay' element={<ClientFirstSurway />} />
                  <Route path='/client-succesful' element={<SpecialistSuccessful />} />
                </> : null}

              {localStorage.getItem("UserID") && localStorage.getItem("UserTYPE") == 2 ?
                <>
                  <Route path='/survay-specialist' element={<Opros />} />
                  <Route path='/specialist-survay' element={<ClientFirstSurway />} />
                  <Route path='/specialist-succesful' element={<SpecialistSuccessful />} />
                </> : null}

              <Route path='/privacy-policy' element={<PrivacyPolicy />} />
              <Route path='*' element={<Navigate to="/" />} />
            </Routes>
            <Footer />
          </>
      }
    </div>
  );
}

export default App;
