const TranslationNL = {
  homeTitle : 'Profyer is een dienst die opdrachtgevers en professionals helpt elkaar te ontmoeten.',
  homeInfo : 'Klanten vinden hier professionals voor elke taak in de hele EU - bouwers, loodgieters, elektriciens, tuinmannen, schoonmakers, kindermeisjes en vele anderen. Laat je contacten achter en je bent als eerste op de hoogte van de lancering van Profyer!',
  formMasterInfo1 : 'Laat uw contacten achter en u:',
  formMasterInfo2 : 'wees als eerste op de hoogte van de lancering van Profyer',
  formMasterInfo3 : 'je ontvangt gratis sollicitaties van onze groepen op sociale netwerken!',
  formUserInfo1 : "Particuliere professionals en bedrijven kunnen met Profyer klanten vinden in de hele Europese Unie.",
  
  survayClientTittle : 'Hoera! Uw aanvraag is geaccepteerd.',
  survayClientInfo1 : ', op dit moment werken we eraan om van de Profyer-service een echt nuttige en handige service te maken voor het vinden van specialisten in verschillende beroepen.',
  survayClientInfo2 : 'Geef ons 3 minuten en vul een korte enquête in - het is belangrijk! Direct een specialist nodig? Plaats een advertentie in onze Facebook-groepen.',
  survayClientInfo3 : 'Bedankt, Profyer-team.',
  name : 'Naam',
  languages : 'Talen',
  surname : 'Achternaam',
  service : 'Beroep',
  register: 'Register',
  startToSurway: "Start de enquête",
  registerUserTittle2: "Klanten vinden hier professionals voor elke taak in de hele EU - bouwers, loodgieters, elektriciens, tuinmannen, schoonmakers, kindermeisjes en vele anderen.",
  registerUserTittle3 : "Laat je contacten achter en je bent als eerste op de hoogte van de lancering van Profyer!",


  next : "Volgende",
  previous : "Vorig",
  privacyIngo : 'Door in account aan te maken ga je akkoord met onze ',
  privacyText : 'Privacyverklaring',
  client : 'Ik ben - Klant',
  specialist : 'Ik ben - Specialist',
  social: "Onze Facebook groepen:",


  send : 'Versturen',
  russian : 'Russisch',
  errorLang: "",
  policy1: "U gaat akkoord",
  policy2: "met de regels voor klanten van Bad Faith, de algemene voorwaarden en het privacybeleid.",
  thanks1: "Bedankt voor het gebruiksvriendelijk maken van onze service!",
  thanks2: "Bedankt voor het gebruiksvriendelijk maken van onze service!",
  surwayClientSpan : "Heb je nu een specialist nodig? Meld je aan voor onze Facebook-groepen om je bestelling te plaatsen",
  surwaySpecialistSpan : "Zoek je nu een baan? Meld je aan voor onze Facebook-groepen om bestellingen te ontvangen",
  selectLabel: " artikelen geselecteerd",







}

export default TranslationNL