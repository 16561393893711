const TranslationES = {
  homeTitle: 'Profyer es un servicio de encuentro entre clientes y profesionales.',
  homeInfo: ' Aquí los clientes podrán encontrar profesionales para cualquier tarea en toda la UE: constructores, fontaneros, electricistas, jardineros, limpiadores, niñeras y muchos otros. ¡Deje sus datos de contacto y será el primero en enterarse del lanzamiento de Profyer!',
  survayClientTittle: '¡Hurra! Su solicitud ha sido aceptada',
  survayClientInfo1: ', tenga en cuenta que en este momento estamos trabajando para que el servicio Profyer sea útil y conveniente en la búsqueda de especialistas en diversas profesiones.',
  survayClientInfo2: '¡Por favor dedique 3 minutos a completar una breve encuesta, es importante! Ya que nos ayudará a brindarle un mejor servicio. ¿Necesitas un especialista ahora mismo? Publica un anuncio en nuestros grupos de Facebook',
  survayClientInfo3: 'Gracias, Equipo Profyer.',
  privacyIngo : 'Al registrarte, aceptas nuestra ',
  privacyText : 'Política de privacidad.',
  registerUserTittle2: "Aquí los clientes podrán encontrar profesionales para cualquier tarea en toda la UE: constructores, fontaneros, electricistas, jardineros, limpiadores, niñeras y muchos otros.",
  registerUserTittle3 : "¡Deje sus datos de contacto y será el primero en enterarse del lanzamiento de Profyer!",
  formMasterInfo1: 'Por favor, deje sus contactos y podrá:',
  formMasterInfo2: 'Ser el primero en enterarse del lanzamiento de Profyer ',
  formMasterInfo3: 'Recibir aplicaciones gratis de nuestros grupos en redes sociales!',
  
  name : "Nombre",
  surname : "Apellido",
  languages : "Idiomas",
  service : "Profesión",
  homeTitle: 'Profyer es un servicio que ayuda a clientes y profesionales a conocer',
  homeInfo: 'Los clientes encontrarán aquí profesionales para cualquier tarea en toda la UE: constructores, fontaneros, electricistas, jardineros, limpiadores, niñeras y muchos otros. Déjanos tus contactos y serás el primero en enterarte del lanzamiento de Profyer',
  formUserInfo1: "Los clientes encontrarán aquí profesionales para cualquier tarea en toda la UE: ",
  client: 'Yo soy - client(e/a)',
  specialist: 'Soy - Especialista',
  register: "Registrar",
  startToSurway: "Empezar la encuesta",
  social: "Nuestros grupos de Facebook:",
  next: "Próximo",
  previous: "Atrás",
  send: 'Enviar',
  policy1: "Usted acepta",
  policy2: "las Reglas, los Términos y condiciones y la Política de privacidad del cliente de mala fe.",
  thanks1: "¡Gracias por hacer que nuestro servicio sea fácil de usar!",
  thanks2: "¡Gracias por hacer que nuestro servicio sea más útil!",
  surwayClientSpan : "Need a specialist right now? Suscríbete a nuestros grupos de Facebook para realizar un pedido ",
  surwaySpecialistSpan : "Looking for a job right now? Regístrese en nuestros grupos de Facebook para comenzar a recibir pedidos",
  selectLabel: " elementos seleccionados",
}

export default TranslationES