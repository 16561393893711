const TranslationPL = {
  homeTitle : 'Profyer to serwis,który pomaga spotykać się klientam i profesjonalistam.',
  homeInfo : 'Tutaj klienci znajdą fachowców róźnych zawodów we wszystkych krajach Unii Europejskiej- budowniczych, hydraulików, fryzjerów i wielu innych. Zostaw swoje kontakty i jako pierwszy dowiesz się o uruchomieniu Profyer',
  formMasterInfo1 : 'Zostaw swoje kontakty,i ',
  formMasterInfo2 : 'jako pierwszy dowiesz się o uruchomieniu Profyer ',
  formMasterInfo3 : 'będziemy na bieżąco informować o nowych wpisach na naszym Facebooku i innych grupach!',
  formUserInfo1 : "Specjaliści i firmy będą mogli znaleźć klientów w całej UE.",
  
  survayClientTittle : 'Wow! Twoje zgloszenie zostalo zaakceptowane.',
  survayClientInfo1 : 'Obecnie pracujemy nad tym, aby Profyer stał się prawdziwym miejscem dochodowych projektów i stałych dochodów.',
  survayClientInfo2 : 'Poświęć 3 minuty na wypełnienie krótkiej ankiety - to ważne! Szukasz pracy teraz? Wszystkie reklamy w naszych grupach na Facebooku. Dziękuję Profyer zespół',
  survayClientInfo3 : 'Dziękuję Profyer zespół ',
  name : 'Nazwa',
  languages : 'Języki',
  surname : 'Nazwisko',
  service : 'Zawód',
  register: 'Rejestr',
  startToSurway: "Rozpocznij ankietę",
  next : "Następny",
  previous : "Poprzedni",
  privacyIngo : 'Rejestrując się, go jest równoznaczne z akceptacją ',
  privacyText : 'Oświadczenia o ochronie prywatności.',
  client : 'Jestem - Klientem',
  specialist : 'Jestem - Specjalista',
  social: "Nasze grupy na Facebook:",
  registerUserTittle2: "Tutaj klienci znajdą fachowców róźnych zawodów we wszystkych krajach Unii Europejskiej- budowniczych, hydraulików, fryzjerów i wielu innych. ",
  registerUserTittle3 : "Zostaw swoje kontakty i jako pierwszy dowiesz się o uruchomieniu Profyer ",



  send : 'Wysłać',
  russian : 'Русский',
  errorLang: "Мова потрібна!",
  errorService: "",
  policy1: "Zgadzasz się",
  policy2: "na Regulamin klienta w złej wierze, Regulamin, Politykę prywatności.",
  thanks1: "Dziękujemy za uczynienie naszej usługi bardziej użyteczną!",
  thanks2: "Dziękujemy za uczynienie naszej usługi bardziej użyteczną!",
  surwayClientSpan : "Potrzebujesz teraz specjalisty? Opublikuj ogłoszenie w jednej z naszych grup na Facebooku.",
  surwaySpecialistSpan : "Szukasz pracy teraz? Wszystkie reklamy w naszych grupach na Facebooku.",
  selectLabel: " wybrane pozycje",  
}

export default TranslationPL