import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Context
import { MyContext } from "../context/Context";

// Components
import { MultiSelect } from "primereact/multiselect";
import { myAxios } from "../service/axios";

export default function SignupUser() {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const [selectedCities, setSelectedCities] = useState([]);
  const [cities, setCities] = useState([]);

  const { user, setUser } = useContext(MyContext);

  const [error, setError] = useState(false);
  const [errMessage, setErrMessage] = useState("");

  const handleInput = (event) => {
    if (event.target.type === "email") {
      setError(false);
      setErrMessage("");
    }
    setUser((p) => ({ ...p, [event.target.id]: event.target.value }));
  };

  function reTranslate(data) {
    const lang = [];

    selectedCities.map((i) => {
      data.map((j) => {
        if (j.id === i.id) {
          lang.push(j);
        }
      });
    });

    setSelectedCities(lang);
  }

  async function getLanguages() {
    try {
      const res = await myAxios("/api/languages/", {
        headers: {
          "Accept-Language": localStorage.getItem("LANGUAGE"),
        },
      });
      setCities(res.data);
      reTranslate(res.data);
    } catch (error) {
      console.log(error);
    }
  }

  function addLangs() {
    let langs = [];
    selectedCities.map((i) => {
      langs.push(i.id);
    });
    return langs;
  }

  useEffect(() => {
    getLanguages();
  }, [localStorage.getItem("LANGUAGE")]);

  async function handleSubmit(event) {
    event.preventDefault();
    const language = addLangs();

    if (language.length === 0) {
      setError(true);
      return;
    }

    try {
      const res = await myAxios.post("/api/user/", {
        ...user,
        language,
        type: "1",
      });
      localStorage.setItem("UserID", res.data.id);
      localStorage.setItem("UserTYPE", res.data.type);
      navigate("/survay-client");
    } catch (err) {
      setError(true);
      setErrMessage(err.response.data.email[0]);
    }
  }

  return (
    <>
      <div data-aos="fade" data-aos-duration="2000" className="SignupUser">
        <div className="container">
          <div className="user-register-card">
            <h3>{t("homeTitle")}</h3>
            <h4 className="mb-1">{t("registerUserTittle2")}</h4>
            <h4 className="mb-4">{t('registerUserTittle3')}</h4>

            <form onSubmit={handleSubmit}>
              <label htmlFor="email">Email</label>
              <input
                required
                className="user-input col-12"
                onChange={handleInput}
                id="email"
                type="email"
                placeholder="test@gmail.com"
                pattern="[a-z-A-Z0-9._%+-]+@[a-z-A-Z0-9.-]+\.[a-z-A-Z]{2,4}$"
              />

              <label htmlFor="email">{t("name")}</label>
              <input
                required
                className="user-input col-12"
                onChange={handleInput}
                type="name"
                id="first_name"
                placeholder={t("name")}
              />

              <label htmlFor="name">{t("surname")}</label>
              <input
                required
                className="user-input col-12"
                onChange={handleInput}
                type="name"
                id="last_name"
                placeholder={t("surname")}
              />

              <label htmlFor="email">{t("languages")}</label>
              <div className="card flex justify-content-center mb-4">
                <MultiSelect
                  value={selectedCities}
                  onChange={(e) => {
                    setError(false);
                    setErrMessage("");
                    setSelectedCities(e.value);
                  }}
                  options={cities}
                  optionLabel="name"
                  selectedItemsLabel={selectedCities.length + t("selectLabel")}
                  placeholder={t("languages")}
                  maxSelectedLabels={3}
                  className="w-full md:w-20rem"
                />
                {error && !errMessage ? (
                  <span className="error-span">{t("errorLang")}</span>
                ) : null}
              </div>

              <div className="button" style={{ position: "relative" }}>
                <button type="submit">{t("register")}</button>
                {error && errMessage ? (
                  <span
                    style={{
                      left: "50%",
                      translate: "-50% 0",
                      width: "max-content",
                    }}
                    className="error-span"
                  >
                    {errMessage}
                  </span>
                ) : null}
              </div>

              <div className="privacy-info">
                <span>{t("privacyIngo")}</span>
                <Link to="/privacy-policy">{t("privacyText")}</Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
