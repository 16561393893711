import { createContext, useState } from "react";

export const MyContext = createContext({});

const LoginContext = ({ children }) => {
  const [user, setUser] = useState({
    first_name: "",
    last_name: "",
    email: "",
    language: "",
    type: 1,
  });

  return (
    <MyContext.Provider value={{ user, setUser }}>
      {children}
    </MyContext.Provider>
  );
};

export default LoginContext;
