import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next";

// Context
import { MyContext } from "../context/Context";

export default function SurvayClient({changeLang}) {
  const { t } = useTranslation();

  const { user } = useContext(MyContext);

  return (
    <div data-aos="fade" data-aos-duration="2000" className='Opros'>
      <div className="container">
        <div className='opros-card'>
          <h3>{t('survayClientTittle')}</h3>
          <p>{(user.first_name || "__NAME__ ") + t('survayClientInfo1')}</p>
          <p>{t('survayClientInfo2')}</p>
          <p>{t('survayClientInfo3')}</p>
          <div className='button'>
            <Link to='/client-survay'>
              <button>{t('startToSurway')}</button>
            </Link>
          </div>

          <span>{t("surwayClientSpan")}</span>
        </div>
      </div>
    </div>
  )
}
