import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MultiSelect } from "primereact/multiselect";
import { MyContext } from "../context/Context";
import { myAxios } from "../service/axios";

export default function SignupSpaecialist({ changeLang }) {
  const navigate = useNavigate();

  const { user, setUser } = useContext(MyContext);

  const [error, setError] = useState({ lang: false, service: false });
  const [errMessage, setErrMessage] = useState("");

  const { t } = useTranslation();

  const [selectedCities, setSelectedCities] = useState([
    { id: 2, name: "Русский" },
  ]);
  const [cities, setCities] = useState([]);

  const [selectedServices, setSelectedServices] = useState([]);
  const [services, setServices] = useState([]);

  useEffect(() => {
    getLanguages();
    getServices();
  }, [localStorage.getItem("LANGUAGE")]);

  function reTranslate(data, isLang) {
    const lang = [];

    if (isLang) {
      selectedCities.map((i) => {
        data.map((j) => {
          if (j.id === i.id) {
            lang.push(j);
          }
        });
      });

      setSelectedCities(lang);
    } else {
      selectedServices.map((i) => {
        data.map((j) => {
          if (j.id === i.id) {
            lang.push(j);
          }
        });
      });

      setSelectedServices(lang);
    }
  }

  // Get all Languages
  async function getLanguages() {
    try {
      const res = await myAxios("/api/languages/", {
        headers: {
          "Accept-Language": localStorage.getItem("LANGUAGE"),
        },
      });
      setCities(res.data);
      reTranslate(res.data, true);
    } catch (error) {
      console.log(error);
    }
  }

  // Get all services
  async function getServices() {
    try {
      const res = await myAxios("/api/services/", {
        headers: {
          "Accept-Language": localStorage.getItem("LANGUAGE"),
        },
      });
      setServices(res.data);
      reTranslate(res.data, false);
    } catch (error) {
      console.log(error);
    }
  }

  function addLangs() {
    let langs = [];
    selectedCities.map((i) => {
      langs.push(i.id);
    });
    return langs;
  }

  function addServices() {
    let langs = [];
    selectedServices.map((i) => {
      langs.push(i.id);
    });
    return langs;
  }

  useEffect(() => {
    getLanguages();
    getServices();
  }, []);

  async function handleSubmit(event) {
    event.preventDefault();
    const language = addLangs();
    const service = addServices();

    if (!language.length) {
      setError((p) => ({ ...p, lang: true }));
      return;
    }
    if (!service.length) {
      setError((p) => ({ ...p, service: true }));
      return;
    }

    try {
      const res = await myAxios.post("/api/user/", {
        ...user,
        language,
        services: service,
        type: "2",
      });
      localStorage.setItem("UserID", res.data.id);
      localStorage.setItem("UserTYPE", res.data.type);
      navigate("/survay-specialist");
    } catch (err) {
      setErrMessage(err?.response?.data.email[0]);
    }
  }

  const handleInput = (event) => {
    if (event.target.type === "email") setErrMessage("");
    setUser((p) => ({ ...p, [event.target.id]: event.target.value }));
  };

  return (
    <div data-aos="fade" data-aos-duration="2000" className="SignupUser">
      <div className="container">
        <div className="user-register-card">
          <h3>{t("homeTitle")}</h3>
          <div>
            <p className="mb-2">
              {t('formUserInfo1')}
            </p>
            <p className="mb-1">
              {t('formMasterInfo1')}
            </p>
            <ul>
              <li>{t('formMasterInfo2')}</li>
              <li>{t('formMasterInfo3')}</li>
            </ul>
          </div>

          <form onSubmit={handleSubmit}>
            <label htmlFor="email">Email</label>
            <input
              required
              type="email"
              id="email"
              className="user-input col-12"
              placeholder="test@gmail.com"
              onChange={handleInput}
              pattern="[a-z-A-Z0-9._%+-]+@[a-z-A-Z0-9.-]+\.[a-z-A-Z]{2,4}$"
            />

            <label htmlFor="name">{t("name")}</label>
            <input
              required
              className="user-input col-12"
              type="text"
              id="first_name"
              placeholder={t("name")}
              onChange={handleInput}
            />

            <label htmlFor="name">{t("surname")}</label>
            <input
              required
              className="user-input col-12"
              type="text"
              id="last_name"
              placeholder={t("surname")}
              onChange={handleInput}
            />

            <label htmlFor="email">{t("languages")}</label>
            <div className="card flex justify-content-center mb-4">
              <MultiSelect
                value={selectedCities}
                onChange={(e) => {
                  setSelectedCities(e.value);
                  setErrMessage("");
                  setError((p) => ({ ...p, lang: false }));
                }}
                options={cities}
                optionLabel="name"
                selectedItemsLabel={selectedCities.length + t("selectLabel")}
                placeholder={t("languages")}
                maxSelectedLabels={3}
                className="w-full md:w-20rem"
              />
              {error.lang && (
                <span className="error-span">{t("errorLang")}</span>
              )}
            </div>

            <label htmlFor="service">{t("service")}</label>
            <div className="card flex justify-content-center mb-4">
              <MultiSelect
                value={selectedServices}
                onChange={(e) => {
                  setSelectedServices(e.value);
                  setErrMessage("");
                  setError((p) => ({ ...p, service: false }));
                }}
                options={services}
                optionLabel="name"
                selectedItemsLabel={selectedServices.length + t("selectLabel")}
                placeholder={t("service")}
                maxSelectedLabels={3}
                className="w-full md:w-20rem"
              />
              {error.service && (
                <span className="error-span">{t("errorService")}</span>
              )}
            </div>

            <div className="button" style={{ position: "relative" }}>
              <button type="submit">{t("register")}</button>
              {errMessage ? (
                <span
                  style={{
                    left: "50%",
                    translate: "-50% 0",
                    width: "max-content",
                  }}
                  className="error-span"
                >
                  {errMessage}
                </span>
              ) : null}
            </div>

            <div className="privacy-info">
              <span>{t("privacyIngo")}</span>
              <Link to="/privacy-policy">{t("privacyText")}</Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
