const TranslationEN = {
  homeTitle: 'Profyer is a service that helps clients and professionals meet',
  homeInfo: ' Clients will find here professionals for any task throughout the EU - builders, plumbers, electricians, gardeners, cleaners, nannies and many others. Leave your contacts and you will be the first to know about the launch of Profyer',
  formMasterInfo1: 'Leave your contacts and you:',
  formMasterInfo2: 'be the first to know about the launch of Profyer ',
  formMasterInfo3: 'you will receive free applications from our groups on social networks!',
  formUserInfo1: "Private professionals and companies will be able to find clients throughout the European Union using Profyer.",
  survayClientTittle: 'Yay! Your application has been accepted. ',
  survayClientInfo1: ', at the moment we are working to make the Profyer service a truly useful and convenient service for finding specialists in various professions.',
  survayClientInfo2: ' Give us 3 minutes and complete a short survey - it\'s important! Need a specialist right now? Post an ad in our Facebook groups.',
  survayClientInfo3: 'Thank you, Profyer Team.',
  name: 'Name',
  languages: 'Languages',
  surname: 'Last name',
  service: 'Profession',
  register: 'Register',
  startToSurway: "Start the survey",
  next: "Next",
  previous: "Previous",
  send: 'Send',
  russian: 'Russian',
  choice: 'Select',
  country: 'Country',
  client: 'I am - Client',
  specialist: 'I am - Specialist',
  errorLang: "Language is required!",
  errorService: "Services is required!",
  privacyIngo : 'By registering, you agree with our ',
  privacyText : 'Privacy Policy.',
  social: "Our Facebook groups:",
  registerUserTittle2: "Clients will find here professionals for any task throughout the EU - builders, plumbers, electricians, gardeners, cleaners, nannies and many others.",
  registerUserTittle3 : "Leave your contacts and you will be the first to know about the launch of Profyer!",
  policy1: "You agree to the ",
  policy2: "Bad Faith Customer Rules, Terms & Conditions, Privacy Policy.",
  thanks1: "Thank you for making our service user friendly!",
  thanks2: "Thank you for making our service user friendly!",
  surwayClientSpan : "Need a specialist right now? Sign up for our Facebook groups to place your order",
  surwaySpecialistSpan : "Looking for a job right now? Sign up for our Facebook groups to start getting orders",
  selectLabel: " items selected",
}

export default TranslationEN