import React from 'react'
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Icon from '../../Images/succesful-icon.svg'

export default function SpecialistSuccessful() {
  const { t } = useTranslation();
  const location = useLocation().pathname;

  return (
    <div className='SpecialistSuccessful' data-aos="fade" data-aos-duration="2000">
      <div className="container">
        <div className="succesful-card">
          <img src={Icon} alt="heart icon" />
          <p> {location === "/client-succesful" ? t("thanks2") : t("thanks1")} <br /> {location === "/client-succesful" ? t("surwayClientSpan") : t("surwaySpecialistSpan")}</p>
        </div>
      </div>
    </div>
  )
}
