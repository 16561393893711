import React from 'react'

export default function PrivacyPolicy() {
  return (
    <div className='PrivacyPolicy'>
      <div className="container">
        <h2>Profyer.com Privacy Policy</h2>
        <p>This privacy policy will explain how Profyer.com uses the personal data we collect from you when you use our website.</p>
        <p>Topics</p>
        <div className='privacy-questions'>
          <h4> • What data do we collect?</h4>
          <h4> • What data do we collect?</h4>
          <h4> • How will we use your data?</h4>
          <h4> • How do we store your data?</h4>
          <h4> • Marketing</h4>
          <h4> • What are your data protection rights?</h4>
          <h4> • What are cookies?</h4>
          <h4> • How do we use cookies?</h4>
          <h4> • Privacy policies of other websites</h4>
          <h4> • Changes to our privacy policy</h4>
          <h4> • How to contact us </h4>
          <h4> • How to contact the appropriate authorities </h4>
        </div>
        <h3>What data do we collect?</h3>
        <p><a href="http://profyer.com/">Profyer.com</a> collects the following data: <br /> • Personal identification information (Name, Last name, email address)</p>
        <h3>How do we collect your data?</h3>
        <p>
          You directly provide <a href="http://profyer.com/">Profyer.com</a> with most of the data we collect. We collect data and process data when you: <br />
          • Register online. <br />
          • Voluntarily complete a customer survey.
        </p>
        <h3>How will we use your data? </h3>
        <p>
          Profyer.com collects your data so that we can: <br />
          • Email you with information about Profyer.com main page launch. <br />
          • Email you with open jobs in Profyer.com social groups.
        </p>
        <h3>How do we store your data?</h3>
        <p><a href="http://proyfer.com/"></a> securely stores your data at public clouds within EU.</p>
        <h3>Marketing</h3>
        <p>If you have agreed to receive marketing, you may always opt out at a later date.You have the right at any time to stop <a href="http://proyfer.com">Profyer.com</a> from contacting you for marketing purposes. If you no longer wish to be contacted for marketing purposes, please contact us via email.</p>
        <h3>What are your data protection rights?</h3>
        <p>
          Profyer.com would like to make sure you are fully aware of all of your data protection rights. <br />
          Every user is entitled to the following: <br /> 
          <b>The right to access</b> - You have the right to request <a href="http://proyfer.com">Profyer.com</a> for copies of your personal data. We may charge you a small fee for this service. <br />
          <b>The right to rectification</b> - You have the right to request that <a href="http://proyfer.com">Profyer.com</a> correct any information you believe is inaccurate. You also have the right to request <a href="http://proyfer.com">Profyer.com</a> to complete information you believe is incomplete. <br />
          <b>The right to erasure</b> - You have the right to request that <a href="http://proyfer.com">Profyer.com</a> erase your personal data, under certain conditions. <br />
          <b>The right to restrict processing</b>  - You have the right to request that Profyer.com restrict the processing of your personal data, under certain conditions. <br />
          <b>The right to object to processing</b> - You have the right to object to Profyer.com processing of your personal data, under certain conditions. <br />
          <b>The right to data portability</b> - You have the right to request that Profyer.com transfer the data that we have collected to another organisation, or directly to you, under certain conditions. <br />
          If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us at our email: info@profyer.com
        </p>
        <h3>What are cookies?</h3>
        <p>Cookies are text files placed on your computer to collect standard Internet log information and visitor behaviour information. When you visit our websites, we may collect information from you automatically through cookies or similar technology.</p>
        <p className='mb40'>For further information, visit: <a href="http://allavoutcookies.org/">allaboutcookies.org</a></p>
        <h3>How do we use cookies?</h3>
        <p className='mb20'>At the moment <a href="http://proyfer.com">Profyer.com</a> doe not use any cookies.</p>
        <h3>Privacy policies of other websites</h3>
        <p className='mb20'>The <a href="http://proyfer.com">Profyer.com</a> website contains links to other websites. Our privacy policy applies only to our website, so if you click on a link to another website, you should read their privacy policy.</p>
        <h3>Changes to our privacy policy</h3>
        <p className='mb20'><a href="http://proyfer.com">Profyer.com</a>  keeps its privacy policy under regular review and places any updates on this web page. This privacy policy was last updated on 01 February 2023.</p>
        <h3>How to contact us</h3>
        <p className='mb20'>If you have any questions about  <a href="http://proyfer.com">Profyer.com</a>'s privacy policy, the data we hold on you, or you would like to exercise one of your data protection rights, please do not hesitate to contact us at our email: <a href="">info@profyer.com</a> </p>
        <h3>How to contact the appropriate authority</h3>
        <p>Should you wish to report a complaint or if you feel that  <a href="http://proyfer.com">Profyer.com</a>  has not addressed your concern in a satisfactory manner, you may contact the information Commissioner's Office.</p>
      </div>
    </div>
  )
}
