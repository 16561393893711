import React from "react";
import { useTranslation } from "react-i18next";

export default function Footer() {
  const { t } = useTranslation();

  return (
    <div className="Footer">
      <div className="container">
        <div className="social-links">
          <h4>{t("social")}</h4>
          <ul>
            <li><a href='https://www.facebook.com/groups/590792223061904' target='_blank'>Profyer Netherlands</a></li>
            <li><a href='https://www.facebook.com/groups/1363441254494742' target='_blank'>Profyer Spain</a></li>
            <li><a href='https://www.facebook.com/groups/1209389173341726' target='_blank'>Profyer Lithuania</a></li>
          </ul>
        </div>
      </div>
    </div>
  );
}
