import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { v4 } from "uuid";

// Components
import Form from "react-bootstrap/Form";
import { MultiSelect } from "primereact/multiselect";
import { InputNumber } from "primereact/inputnumber";
import { useTranslation } from "react-i18next";

// Context
import { myAxios } from "../../service/axios";
import { InputText } from "primereact/inputtext";

export default function ClientFirstSurway() {
  const location = useLocation().pathname;
  const navigate = useNavigate();
  const [formValue, setFormValue] = useState({});

  const [questions, setQuestions] = useState(null);
  const [questionsURL, setQuestionsURL] = useState(
    location === "/client-survay" ? 1 : 4
  );
  const [isNewQues, setIsNewQues] = useState(
    location === "/client-survay" ? 0 : 3
  );

  const [persent, setPersent] = useState([0, ""]);
  const [ageID, setAgeID] = useState(0);
  const [text, setText] = useState("");
  const [textID, setTextID] = useState([0, 0]);
  const [numberID, setNumberID] = useState(0);
  const [countryID, setCountryID] = useState(0);
  const [serviceID, setServiceID] = useState(0);

  const [countries, setCountries] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);

  const [services, setServices] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const difficultNum = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  function setQuestId(data) {
    let newData = {};
    data.map((i) => {
      newData = { ...newData, [i.id]: [] };
    });

    setFormValue((p) => ({ ...newData, ...p }));
  }

  useEffect(() => {
    getQuestions();
    window.scrollTo(0, 0);
  }, [questionsURL]);

  function prevPage() {
    location === "/client-survay"
      ? questionsURL === 1
        ? navigate("...")
        : setQuestionsURL((p) => --p)
      : questionsURL === 4
      ? navigate("...")
      : setQuestionsURL((p) => --p);
  }

  function reTranslate(data, type) {
    const lang = [];

    if (type === "service") {
      selectedServices.map((i) => {
        data.map((j) => {
          if (j.id === i.id) {
            lang.push(j);
          }
        });
      });

      setSelectedServices(lang);
    } else {
      selectedCountries.map((i) => {
        data.map((j) => {
          if (j.id === i.id) {
            lang.push(j);
          }
        });
      });

      setSelectedCountries(lang);
    }
  }

  // Get all services
  async function getServices() {
    try {
      const res = await myAxios("/api/services/", {
        headers: {
          "Accept-Language": localStorage.getItem("LANGUAGE"),
        },
      });
      setServices(res.data);
      reTranslate(res.data, "service");
    } catch {}
  }

  // Get all countries
  async function getCountries() {
    try {
      const res = await myAxios("/api/countries/", {
        headers: {
          "Accept-Language": localStorage.getItem("LANGUAGE"),
        },
      });
      setCountries(res.data);
      reTranslate(res.data, "country");
    } catch {}
  }

  // Get questions
  async function getQuestions() {
    try {
      const res = await myAxios(`/api/questions/?p=${questionsURL}`, {
        headers: {
          "Accept-Language": localStorage.getItem("LANGUAGE"),
        },
      });
      setQuestions(res.data);

      for (const ques of res.data.results[0]?.category) {
        setQuestId(ques.questions.sort((a, b) => a.id - b.id));
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getQuestions();

    if (services.length !== 0) {
      getServices();
    }
    if (countries.length !== 0) {
      getCountries();
    }
  }, [localStorage.getItem("LANGUAGE")]);

  function addServices(type) {
    let langs = [];

    if (type === "service") {
      selectedServices.map((i) => {
        langs.push(i.id);
      });
    }
    if (type === "country") {
      selectedCountries.map((i) => {
        langs.push(i.id);
      });
    }
    return langs;
  }

  useEffect(() => {
    if (selectedServices.length !== 0) {
      setFormValue((p) => ({
        ...p,
        [serviceID]: addServices("service"),
      }));
    }

    if (selectedCountries.length !== 0) {
      setFormValue((p) => ({
        ...p,
        [countryID]: addServices("country"),
      }));
    }
  }, [selectedServices, selectedCountries]);

  const { t } = useTranslation();

  async function handleSubmit(e) {
    e.preventDefault();

    if (
      (location === "/client-survay" && questionsURL !== 3) ||
      (location === "/specialist-survay" && questionsURL !== 6)
    ) {
      setQuestionsURL((p) => ++p);
    } else {
      let answers = [];

      for (const object in formValue) {
        if (numberID == object && formValue?.[object].length !== 0) {
          answers.push({
            question_id: Number(object),
            options: formValue?.[object],
            number: Number(formValue?.[object][0]),
          });
        } else if (ageID == object && formValue?.[object].length !== 0) {
          answers.push({
            question_id: Number(object),
            options: formValue?.[object],
            age: Number(formValue?.[object][0]),
          });
        } else if (formValue?.[object].length !== 0 || textID[1]) {
          if (Number(object) === textID[0] && textID[1]) {
            answers.push({
              question_id: Number(object),
              options: formValue?.[object],
              variant: text,
            });
          } else {
            answers.push({
              question_id: Number(object),
              options: formValue?.[object],
            });
          }
        } else if (persent[1] == object && persent[0]) {
          answers.push({
            question_id: Number(object),
            options: [Number(persent[0])],
            percent: Number(persent[0]),
          });
        }
      }

      try {
        await myAxios.post("/api/answers/", {
          data: {
            user_id: Number(localStorage.getItem("UserID")),
            answers: answers,
          },
        });
        location === "/client-survay"
          ? navigate("/client-succesful")
          : navigate("/specialist-succesful");
      } catch (err) {
        console.log(err);
      }
    }
  }

  function formValueEdit(param, name, type) {
    if (formValue?.[param].includes(name)) {
      let params = [];
      formValue?.[param].map((i) => (i !== name ? params.push(i) : null));

      setFormValue((p) => ({
        ...p,
        [param]: params,
      }));
    } else {
      let params = formValue?.[param];
      setFormValue((p) => ({
        ...p,
        [param]: [...params, name],
      }));
    }
  }

  function handleSelect(e, type) {
    type === "service"
      ? setSelectedServices(e.value)
      : setSelectedCountries(e.value);
  }

  const [inputs, setInputs] = useState([]);

  useEffect(() => {
    if (!text) {
      setTextID([0, 0]);
    }
  }, [text]);

  useEffect(() => {

    if(!inputs.length || isNewQues === questionsURL - 1) {

      questions?.results[0]?.category.map((category) => {
        
        category.questions.map((ques) => {
          if (ques.type === "CHECKBOX") {
            const options = [];
            ques.options.map((i) => {
              options.push({ id: i.id, checked: false, sequence: null });
            });
            inputs.push({
              id: ques.id,
              options,
              numValue: 1,
            });
          }
        });
      });
      setIsNewQues(questionsURL);
  }
  }, [questions]);

  const handleCheckboxChange = (idx, jdx) => {

    inputs.map((input) => {
      
      if (input.id === idx) {

        input.options.map((option) => {
          if (option.id === jdx) {

            if (input.numValue - 1 === option.sequence) {
              option.sequence = null;
              input.numValue -= 1;

            } else if (option.sequence === null) {
              option.sequence = input.numValue;
              input.numValue += 1;
              
            } else if (input.numValue - 1 > option.sequence) {
              let currNum = option.sequence;
              option.sequence = null;

              input.options
                .sort((a, b) => a.sequence - b.sequence)
                .map((i) => {
                  if (currNum + 1 === i.sequence) {
                    i.sequence = currNum;
                    currNum += 1;
                  }
                });
              input.numValue -= 1;
            }
          }
        });
      }
    });
  };

  function getCurrentID(idx, jdx) {
    let currID = "";

    inputs.map((input) => {
      if (input.id === idx) {

        input.options.map((option) => {

          if (option.id === jdx) {
            currID = option.sequence;
          }
        });
      }
    });

    return currID;
  }

  return (
    <form onSubmit={handleSubmit} data-aos="fade" data-aos-duration="2000">
      <div className="Surway1">
        {questions ? (
          <div className="container">
            <div className="surway-blog">
              <div className="surway-body">
                {questions.results[0].category.map((category) => {
                  return (
                    <div key={category.id}>
                      <h3
                        className="surway-tittle"
                        style={{ marginRight: "-15%" }}
                      >
                        {category.name}
                      </h3>
                      {category.questions.map((i) => (
                        <div key={i.id} className="surway-body__check-form ">
                          <h3 className="surway-tittle">{i.text}</h3>

                          {i.options.map((j, jdx) =>
                            i.type === "CHOICE" ? (
                              <Form.Check
                                required
                                key={v4()}
                                type={"radio"}
                                label={j.text}
                                name={i.id}
                                id={j.id}
                                checked={formValue?.[i.id][0] == j.id}
                                onChange={() =>
                                  setFormValue((p) => ({
                                    ...p,
                                    [i.id]: [j.id],
                                  }))
                                }
                              />
                            ) : i.type === "PERCENT" ? (
                              <div
                                key={v4()}
                                style={{ display: "flex", gap: "10px" }}
                              >
                                <Form.Check
                                  required
                                  type={"radio"}
                                  label={jdx === 0 ? j.text : null}
                                  name={i.id}
                                  id={j.id}
                                  checked={
                                    (jdx === 0 &&
                                      formValue?.[i.id][0] === j.id) ||
                                    (jdx === 1 && persent[0] !== 0)
                                  }
                                  onChange={() => {
                                    setFormValue((p) => ({
                                      ...p,
                                      [i.id]: [j.id],
                                    }));
                                    setPersent([0, ""]);
                                  }}
                                />
                                {jdx === 1 && (
                                  <div>
                                    <InputNumber
                                      style={{
                                        maxWidth: "50px",
                                        marginRight: "5px",
                                        border: "none",
                                        borderBottom: "1px solid #000",
                                      }}
                                      value={persent[0]}
                                      onBlur={(e) => {
                                        setFormValue((p) => ({
                                          ...p,
                                          [i.id]: [],
                                        }));
                                        setPersent([e.target.value, i.id]);
                                      }}
                                      min={0}
                                      max={100}
                                      inputStyle={{
                                        padding: "0 3px",
                                        maxWidth: "50px",
                                        border: "none",
                                        borderRadius: "0px",
                                      }}
                                    />
                                    <label htmlFor="percent">{j.text}</label>
                                  </div>
                                )}
                              </div>
                            ) : i.type === "CHECKBOX" ? (
                              j.id !== 189 ? (
                                <div key={v4()} className="checkbox-wrapper">
                                  <Form.Check
                                    className="checkbox-input"
                                    type="checkbox"
                                    required={
                                      i.id === textID[0] ? !textID[1] === j.id : !formValue?.[i.id].length
                                    }
                                    label={j.text}
                                    name={i.id}
                                    id={j.id}
                                    checked={formValue?.[i.id].includes(j.id)}
                                    onChange={() => {
                                      formValueEdit(i.id, j.id, i.type);
                                      handleCheckboxChange(i.id, j.id);
                                    }}
                                  />
                                  {formValue?.[i.id].includes(j.id) && <span
                                    className="checkbox-custom"
                                    onClick={() => {
                                      formValueEdit(i.id, j.id, i.type);
                                      handleCheckboxChange(i.id, j.id);
                                    }}
                                  >
                                    {getCurrentID(i.id, j.id)}
                                  </span>}
                                </div>
                              ) : (
                                <div
                                  key="your-variant"
                                  style={{
                                    display: "flex",
                                    alignItems: "flex-end",
                                    gap: "12px",
                                    flexWrap: "wrap",
                                  }}
                                  className="checkbox-wrapper"
                                >
                                  <Form.Check
                                    key={v4()}
                                    type={"checkbox"}
                                    checked={textID[1]}
                                    className="checkbox-input"
                                    label={j.text}
                                    readOnly
                                    name={i.id}
                                    onChange={() => {
                                      if (textID[1] !== 0) {
                                        setText("");
                                        handleCheckboxChange(i.id, j.id);
                                      }
                                    }}
                                    id={j.id}
                                  />
                                  {<span
                                  style={{marginTop: "5px"}}
                                    className="checkbox-custom"
                                    onClick={() => {
                                      handleCheckboxChange(i.id, j.id);
                                    }}
                                  >
                                    {getCurrentID(i.id, j.id)}
                                  </span>}
                                  <input
                                    type="text"
                                    style={{
                                      padding: "3px 5px",
                                      width: "70%",
                                      border: "none",
                                      borderRadius: "0px",
                                      borderBottom: "1px solid #000",
                                    }}
                                    value={text}
                                    onChange={(e) => {
                                      if(!textID[1]) {
                                        handleCheckboxChange(i.id, j.id);
                                      }
                                      if(!e.target.value) {
                                        handleCheckboxChange(i.id, j.id);
                                      }
                                      setTextID([i.id, j.id]);
                                      setText(e.target.value);
                                    }}
                                  />
                                </div>
                              )
                            ) : null
                          )}
                          {i.type === "SERVICES" ? (
                            <MultiSelect
                              value={selectedServices}
                              onChange={(e) => {
                                setServiceID(i.id);
                                handleSelect(e, "service");
                              }}
                              onMouseDown={() =>
                                services.length === 0 ? getServices() : null
                              }
                              style={{
                                width: "245px",
                              }}
                              options={services}
                              selectedItemsLabel={
                                selectedServices.length + t("selectLabel")
                              }
                              optionLabel="name"
                              placeholder={t("service")}
                              maxSelectedLabels={2}
                            />
                          ) : i.type === "COUNTRY" ? (
                            <MultiSelect
                              value={selectedCountries}
                              onChange={(e) => {
                                setCountryID(i.id);
                                handleSelect(e, "country");
                              }}
                              onMouseDown={() =>
                                countries.length === 0 ? getCountries() : null
                              }
                              style={{
                                width: "245px",
                              }}
                              selectedItemsLabel={
                                selectedCountries.length + t("selectLabel")
                              }
                              options={countries}
                              optionLabel="name"
                              placeholder={t("country")}
                              maxSelectedLabels={2}
                            />
                          ) : i.type === "NUMBER" ? (
                            <div
                              key={v4()}
                              className="mb-3 d-flex gap-3 flex-wrap"
                            >
                              {difficultNum.map((j) => (
                                <Form.Check
                                  key={v4()}
                                  required
                                  type={"radio"}
                                  checked={formValue?.[i.id][0] == j}
                                  label={j}
                                  name={i.id}
                                  onChange={() => {
                                    setNumberID(i.id);
                                    setFormValue((p) => ({
                                      ...p,
                                      [i.id]: [j],
                                    }));
                                  }}
                                />
                              ))}
                            </div>
                          ) : i.type === "AGE" ? (
                            <div
                              key={v4()}
                              style={{ display: "flex", gap: "10px" }}
                            >
                              <Form.Check
                                type={"radio"}
                                name={i.id}
                                required
                                checked={Number(formValue?.[i.id][0]) > 17}
                                onChange={(e) => (e.target.required = true)}
                              />
                              <InputNumber
                                style={{
                                  maxWidth: "50px",
                                  marginRight: "5px",
                                  border: "none",
                                  borderBottom: "1px solid #000",
                                }}
                                value={formValue?.[i.id][0]}
                                onValueChange={() => setAgeID(i.id)}
                                onBlur={(e) => {
                                  setFormValue((p) => ({
                                    ...p,
                                    [i.id]: [Number(e.target.value)],
                                  }));
                                }}
                                min={0}
                                max={90}
                                inputStyle={{
                                  padding: "0 3px",
                                  maxWidth: "50px",
                                  border: "none",
                                  borderRadius: "0px",
                                }}
                              />
                            </div>
                          ) : null}
                        </div>
                      ))}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="button">
              {questionsURL !== 1 && questionsURL !== 4 ? (
                <button type="button" onClick={prevPage}>
                  {t("previous")}
                </button>
              ) : null}
              <button
                className={
                  questionsURL !== 1 && questionsURL !== 4
                    ? "second-button"
                    : "btn"
                }
                type="submit"
              >
                {questionsURL === 3 || questionsURL === 6
                  ? t("send")
                  : t("next")}
              </button>
            </div>
          </div>
        ) : null}
      </div>
    </form>
  );
}
