import React from 'react'
import Logo from '../Images/Logo.svg'
export default function Loader() {
  return (
    <div className='Loader'>
      <div className="container">
        <div className='loader-body'>
          <img src={Logo} alt="logo" />
        </div>
      </div>
    </div>
  )
}
