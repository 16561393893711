const TranslationUA = {
  homeTitle : 'Profyer — це сервіс, який допомагає клієнтам та спеціалістам зустрітися.',
  homeInfo : 'Клієнти знаходитимуть тут професіоналів для вирішення будь-яких завдань по всій території Євросоюзу — будівельників, сантехніків, електриків, садівників, прибиральників, нянь та багатьох інших. Залишіть свої контакти, і Ви дізнаєтесь першими про запуск Profyer!',
  formMasterInfo1 : 'Залишіть свої контакти, і Ви:',
  formMasterInfo2 : 'дізнаєтеся першими про запуск Profyer',
  formMasterInfo3 : 'отримуватимете безкоштовні заявки з наших груп у соціальних мережах!',
  formUserInfo1 : "Приватні фахівці та компанії зможуть знаходити за допомогою Profyer клієнтів на всій території Євросоюзу.",
  
  survayClientTittle : 'Ура! Ваша заявка прийнята.',
  survayClientInfo1 : ', зараз ми працюємо над тим, щоб сервіс Profyer став по-справжньому корисним та зручним сервісом з пошуку фахівців різних професій.',
  survayClientInfo2 : 'Приділіть нам 3 хвилини та пройдіть коротке опитування – це важливо! Потрібен фахівець прямо зараз? Розмістіть оголошення у наших Facebook групах.',
  survayClientInfo3 : 'Дякую, Команда Profyer.',
  name : "Ім'я",
  languages : 'Мови',
  surname : 'Прізвище',
  service : 'Професія',
  register: 'Зареєструватись',
  startToSurway: "Пройти опитування",
  next : "Далі",
  previous : "Назад",
  privacyIngo : 'Реєструючись, Ви погоджуєтесь з нашою ',
  privacyText : 'Політикою конфіденційності',
  client : 'Я - Клієнт',
  specialist : 'Я - Cпеціаліст',
  social: "Hаші Facebook групи:",
  registerUserTittle2: "Клієнти знаходитимуть тут професіоналів для вирішення будь-яких завдань по всій території Євросоюзу — будівельників, сантехніків, електриків, садівників, прибиральників, нянь та багатьох інших.",
  registerUserTittle3 : "",

  send : 'Відправити',
  russian : 'Русский',
  errorLang: "Мова потрібна!",
  errorService: "",
  policy1: "Ви погоджуєтеся з",
  policy2: "правилами, положеннями та умовами та політикою конфіденційності Bad Faith.",
  thanks1: "Дякую, що робите наш сервіс кориснішим!",
  thanks2: "Дякую, що робите наш сервіс кориснішим!",
  surwayClientSpan : "Потрібен фахівець прямо зараз? Розмістіть оголошення у наших Facebook групах.",
  surwaySpecialistSpan : "Шукаєте роботу прямо зараз? Всі оголошення в наших Facebook",
  selectLabel: " вибрано елементів",
}

export default TranslationUA