import React from "react";
import img1 from "../Images/specialist-logo.svg";
import img2 from "../Images/user-logo.svg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Signup() {
  const { t } = useTranslation();

  return (
    <div data-aos="fade" data-aos-duration="2000" className="Signup">
      <div className="container">
        <div className="preyfor-card">
          <h3>{t("homeTitle")}</h3>
          <p>{t("homeInfo")}</p>

          <div className="preyfor-card__cards">
            <div className="cards__card">
              <img src={img1} alt="specialist user icon" />
              <Link to="/specialist-registration">
                <button>{t("specialist")}</button>
              </Link>
            </div>

            <div className="cards__card">
              <img src={img2} alt="client user icon" />

              <Link to="/client-registration">
                <button>{t("client")}</button>
              </Link>
            </div>
          </div>

          <div className="mobile-verson-buttons">
            <Link to="/client-registration">
              <button className="client-button">{t("client")}</button>
            </Link>

            <Link to="/specialist-registration">
              <button className="specialist-button">{t("specialist")}</button>
            </Link>
          </div>

          <div className='privacy-info'>
              <span>{t('privacyIngo')}</span>
              <Link to='/privacy-policy'>{t("privacyText")}</Link>
          <div className="privacy-info">
            
          </div>
        </div>
      </div>
    </div>
    </div>

  );
}
