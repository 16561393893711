const TranslationLt = {
  homeTitle : 'Profyer – tai servisas, padedantis klientams ir specialistams susitikti.',
  homeInfo : 'Čia klientai ras profesionalus visose Europos Sąjungos šalyse įvairių darbų atlikimui – statybininkus, santechnikus, kirpėjus, ir daugelį kitų. Palikite savo kontaktus ir mes jus informuosime, kai tik paleisime servisą!',
  formMasterInfo1 : 'Palikite savo kontaktus ir',
  formMasterInfo2 : 'būsite pirmi sužinoję apie Profyer paleidimą',
  formMasterInfo3 : 'informuosime jus apie naujus pranešimus mūsų Facebook ir kitose grupėse!',
  formUserInfo1 : "Specialistai ir įmonės galės rasti klientus visoje ES.",
  
  survayClientTittle : 'Valio! Jūsų paraiška priimta.',
  survayClientInfo1 : ', šiuo metu stengiamės, kad Profyer servisas iš tikrųjų taptu naudingu ir patogiu servisu, kur būtų galima rasti įvairių profesijų specialistus.',
  survayClientInfo2 : 'Paskirkite 3 minutes ir atlikite trumpą apklausą – tai svarbu! Reikia specialisto dabar? Įdėkite skelbimą vienoje is mūsų Facebook grupių.',
  survayClientInfo3 : 'Dekui, Profyer Komanda.',
  name : 'Vardas',
  languages : 'Kalbos',
  surname : 'Pavardė',
  service : 'Profesija',
  register: 'Registruotis',
  startToSurway: "Pradeti apklausą",
  next : "Toliau",
  previous : "Atgal",
  privacyIngo : 'Sukurdami paskyrą jūs sutinkate su mūsų ',
  privacyText : 'Privatumo nuostatais',
  client : 'Aš esu - Klientas',
  specialist : 'Aš – Specialistas',
  social: "Mūsų Facebook grupės:",
  registerUserTittle2: "Čia klientai ras profesionalus visose Europos Sąjungos šalyse įvairių darbų atlikimui – statybininkus, santechnikus, kirpėjus,  ir daugelį  kitų.",
  registerUserTittle3 : "Palikite savo kontaktus ir mes jus informuosime, kai tik paleisime servisą!",



  send : 'Patvirtinti',
  russian : 'Rusų',
  policy1: "Jūs sutinkate",
  policy2: "su Bad Faith klientų taisyklėmis, sąlygomis ir privatumo politika.",
  thanks1: "Dėkojame, kad gerinate mūsų paslaugą!",
  thanks2: "Dėkojame, kad gerinate mūsų paslaugą!",

  surwayClientSpan : "Reikia specialisto dabar? Įdėkite skelbimą vienoje is mūsų Facebook grupių.",
  surwaySpecialistSpan : "Ieškote darbo dabar? Visi skelbimai mūsų Facebook grupėse.",
  selectLabel: " pasirinkti elementai",






}

export default TranslationLt